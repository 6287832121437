<template>
  <section>
    <b-table-simple class="table" striped responsive borderless fixed>
      <thead>
        <tr>
          <th>Nome/Razão social</th>
          <th>CPF/CNPJ</th>
          <th>Favorecido</th>
          <th>E-mail</th>
          <th>Telefone</th>
          <th>Cidade/Estado</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(beneficiary, index) in beneficiaries" :key="index">
          <td>
            {{ beneficiary.name }}
          </td>
          <td>
            {{ beneficiary.number ? beneficiary.number : '-' }}
          </td>
          <td>
            {{
              beneficiary.type === 'CLINIC_PROFESSIONAL' ? 'Profissional da clínica' : 'Favorecido externo'
            }}
          </td>
          <td>
            <Ellipsis>
              {{ beneficiary.email ? beneficiary.email : '-' }}
            </Ellipsis>
          </td>
          <td>
            {{ beneficiary.phone ? beneficiary.phone : '-' }}
          </td>
          <td>
            {{
              beneficiary.address && beneficiary.address.city
                ? `${beneficiary.address.city},`
                : ''
            }}
            {{
              beneficiary.address && beneficiary.address.state
                ? `${beneficiary.address.state}`
                : ''
            }}
          </td>
          <td>
            <div class="actions">
              <Icon tooltip="Editar beneficiário">
                <Edit class="icon" @click="editBeneficiary(beneficiary)" />
              </Icon>
              <Icon tooltip="Remover beneficiário">
                <b-button
                  class="delete"
                  :disabled="beneficiary.type === 'CLINIC_PROFESSIONAL'"
                  @click="deleteBeneficiary(beneficiary)"
                >
                  <Delete
                    :disabled="beneficiary.type === 'CLINIC_PROFESSIONAL'"
                    class="icon"
                  />
                </b-button>
              </Icon>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </section>
</template>

<script>
export default {
  props: {
    beneficiaries: Array
  },
  components: {
    Icon: () => import('@/components/General/Icon'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis')
  },
  methods: {
    editBeneficiary(beneficiary) {
      this.$emit('edit', beneficiary)
    },
    deleteBeneficiary(beneficiary) {
      this.$emit('delete', beneficiary)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.delete {
  background-color: transparent;
  border: none;
}
.delete:disabled {
  cursor: not-allowed;
}
.actions {
  justify-content: right;
  display: flex;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
  .delete:disabled {
    .icon {
      cursor: not-allowed;
    }
  }
}
</style>
